<template>
  <div class="shipments-page p-5">
    <div class="flex w-full">
      <vs-button class="mt-5 h-14 rounded-none rounded-l-lg" color="primary" icon-pack="feather" icon="icon-camera" @click="startCam"></vs-button>
      <vs-input v-validate="'numeric'" size="large" name="package" class="scan-input w-full" :label-placeholder="$t('Scan with barcode or click camera')" v-model="sealCode" @change="[sealCode? onDecode(sealCode): '']" />
    </div>
    <StreamBarcodeReader v-if="startedCamera" @decode="onDecode"></StreamBarcodeReader><br><br>
    <div class="vx-row">
      <div class="vx-col text-center w-full mb-base">
        <h2 class="mb-3">{{$t('Hi')}}</h2>
        <h2 class="mb-3">{{$t('I\'m at')}}</h2>
        <v-select class="select-large w-full" v-model="fromWarehouse"
          :placeholder="$t('From Warehouse')" label="name" :options="warehouses"/>
      </div>
      <div v-if="fromWarehouse" class="vx-col text-center w-full mb-base">        
        <h2 class="mb-3" :class="seal.status === 'dropped_off' ? 'en_route' : ''" :key="seal.index" v-for="seal in dropSeals">
          <div class="text-center mb-4 borderd border-solid border-2 p-4">
            <img v-if="seal.fragile" class="w-14 mr-auto ml-auto" src="../../assets/images/pages/fragile.png">
            <p class="text-center ">{{$t('Unload Seal No.')}} {{ seal.code }}</p>
          </div>
        </h2>
      </div>
      <template v-if="fromWarehouse">
        <div class="vx-col text-center w-full mb-base">
          <h2 class="mb-3">{{$t('Going to')}}</h2>
          <v-select class="select-large w-full" v-model="toWarehouses" multiple
            :placeholder="$t('To Warehouse')" label="name" :options="warehouses"/>
        </div>
        <div class="vx-col text-center w-full mb-base" :key="seal.index" v-for="seal in seals">

          <h2 class="mb-3" v-for="item in seal.seals" :key="item.index" :class="item.status">
            <div class="text-center mb-4 borderd border-solid border-2 p-4">
              <img v-if="item.fragile" class="w-14 mr-auto ml-auto" src="../../assets/images/pages/fragile.png">
              <p class="text-center ">{{$t('Pick Seal No. ')}} {{ item.code }} {{$t('going from')}} {{fromWarehouse.code}} {{$t('to')}} {{seal.warehouse}}</p>
            </div>
          </h2>
        </div>
      </template>
    </div>
    <vs-button class="w-full" color="danger" @click="exit()">{{$t('Exit')}}</vs-button>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import vSelect from 'vue-select'
import common  from '@/assets/utils/common'
import { StreamBarcodeReader } from 'vue-barcode-reader-plus'
import i18nData from '../../i18n/i18nData.js'
export default {
  data () {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      fromWarehouse: '',
      toWarehouses: [],
      startedCamera: false,
      sealCode: '',
      warehouses: [],
      warehousesDic: {},
      seals: [],
      sealsDic: {},
      sealsCodes: [],
      dropSeals: []
    }
  },
  watch: {
    fromWarehouse (val) {
      if (val) {
        this.toWarehouses = []
        this.loadSeal('dropOff', val.code)
      }
    },
    toWarehouses (val) {
      this.seals = []
      if (val.length > 0) {
        val.forEach(item => {
          this.loadSeal('pick', item.code)
        })
      }
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      this.warehouses = warehouses.filter(warehouse =>  warehouse.is_virtual === false)
      this.warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = warehouse.code
      })
    },
    startCam () {
      this.startedCamera = !this.startedCamera
      this.onDecode()
    },
    onDecode (result) {
      if (result !== undefined) {
        let updatedStatus = ''
        if (this.startedCamera) {
          this.startedCamera = !this.startedCamera
        }
        this.sealCode = ''
        updatedStatus = this.sealsDic[result] && this.sealsDic[result].status === 'sealed' ? 'en_route' : 'dropped_off'
        if (this.sealsCodes.includes(result) && (updatedStatus === 'en_route' || (updatedStatus === 'dropped_off' && this.sealsDic[result].warehouse === this.fromWarehouse.code))) {
          this.updateSeal(this.sealsDic[result], updatedStatus)
        } else {
          this.$vs.notify({
            color:'danger',
            title: i18nData[this.$i18n.locale]['Failed'],
            text:  i18nData[this.$i18n.locale]['Seal'] + i18nData[this.$i18n.locale][' not found!'],
            position: 'top-center'
          })
        }
      }
    },
    loadSeal (type, warehouseCode) {
      this.$vs.loading()
      let url = ''
      if (type === 'dropOff') {
        url = `api/v1/seals/?to_warehouse=${warehouseCode}&status=en_route`
      } else {
        url = `api/v1/seals/?from_warehouse=${this.fromWarehouse.code}&to_warehouse=${warehouseCode}&status=sealed`
      }
      sendRequest(false, false, this, url, 'get', null, true,
        (response) => {
          response.data.results.forEach(element => {
            if (!this.sealsCodes.includes(element.code)) {
              this.sealsCodes.push(element.code)
            }
            this.sealsDic[element.code] = {
              status: element.status,
              id: element.id,
              warehouse: this.warehousesDic[element.bin.to_warehouse]
            }
          })
          if (type === 'pick') {         
            this.seals.push({
              warehouse: warehouseCode,
              seals: response.data.results
            })
          } else {
            this.dropSeals = response.data.results
          }
        }
      )
    },
    updateSeal (seal, status) {
      const sealObj = {
        seal: seal.id,
        status,
        warehouse: status === 'dropped_off' ? this.fromWarehouse.id : '' 
      }
      sendRequest(false, false, this, 'api/v1/tracking/seals/', 'post', sealObj, true,
        () => {
          switch (status) {
          case 'dropped_off':
            this.dropSeals.forEach(element => {
              if (element.id === seal.id) {
                element.status = status
              }
            })
            break
          case 'en_route':
            this.seals.forEach(element => {
              element.seals.forEach(item => {
                if (item.id === seal.id) {
                  item.status = status
                }
              })
            })
            break
          }
          this.$vs.notify({
            color:'success',
            title: i18nData[this.$i18n.locale]['Success'],
            text:  i18nData[this.$i18n.locale]['Seal'] + i18nData[this.$i18n.locale][' has been updated successfully!'],
            position: 'top-center'
          })
        }
      )
    },
    exit () {
      common.manageLoadWarehouses(this.formatWarehouses, this)
      this.fromWarehouse = {}
      this.toWarehouses = []
    }
  },
  components: {
    vSelect,
    StreamBarcodeReader
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
  }
}
</script>
<style lang="scss">
.borderd {
  border-color: rgb(203, 213, 225);
}
.en_route {
  color: #28c76f
}
</style>